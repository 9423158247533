module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"The Men's Health Clinic Canada for ED and PE","description":"MHC has achieved a high success rate over 10 years in empowering men to seek help for ED or PE. Our Licensed doctors create customised treatments based on your individual needs which has lead to a great reputation with 5 star Google reviews.","openGraph":{"type":"website","url":"https://menshealthclinic.ca/","title":"The Men's Health Clinic Canada for ED and PE","description":"MHC has achieved a high success rate over 10 years in empowering men to seek help for ED or PE. Our Licensed doctors create customised treatments based on your individual needs which has lead to a great reputation with 5 star Google reviews."},"twitter":{"cardType":"summary_large_image","url":"https://menshealthclinic.ca/"},"metaTags":[{"name":"keywords","content":"australia, au, men's health clinic, mhc, men's, health, clinic, treatment, premature ejaculation, pe, erectile dysfunction, ed, online assessment, australian doctors"},{"name":"robots","content":"index, follow"},{"name":"language","content":"English"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"theme-color","content":"#000000"}],"languageAlternates":[{"hrefLang":"x-default","href":"https://menshealthclinic.ca/"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WQF6FHR","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://menshealthclinic.ca/","noTrailingSlash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Men's Health Clinic","short_name":"MHC","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/favicon/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"12d3a5769ff3e8fc2d9af2832aa108ff"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
