exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-erectile-dysfunction-2-minute-quiz-js": () => import("./../../../src/pages/erectile-dysfunction/2-minute-quiz.js" /* webpackChunkName: "component---src-pages-erectile-dysfunction-2-minute-quiz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-premature-ejaculation-2-minute-quiz-js": () => import("./../../../src/pages/premature-ejaculation/2-minute-quiz.js" /* webpackChunkName: "component---src-pages-premature-ejaculation-2-minute-quiz-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

